import type { SVGProps } from "react"

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ outline: "none" }}
    {...props}
  >
    <path d="M5.63623 5.63605L18.3642 18.364" />
    <path d="M5.63574 18.364L18.3637 5.63603" />
  </svg>
)
export default CloseIcon
