"use client"

import {
  Children,
  cloneElement,
  FC,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useCallback,
  useDeferredValue,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react"
import clsx from "clsx"
import { createPortal } from "react-dom"
import { XSmall } from "styles/Type"

type TooltipInnerProps = {
  id: string
  children: ReactNode
  variant?: "primary" | "secondary" | "dark"
  direction?: "top" | "right" | "bottom" | "left"
  rect: DOMRect | null
}

const Tooltip: FC<TooltipInnerProps> = ({
  id,
  children,
  variant = "primary",
  direction = "right",
  rect,
}) => {
  const portalRef = useRef<HTMLElement | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    portalRef.current = document.getElementById("tooltip-root")
    setMounted(true)
  }, [])

  const getStyle = useCallback(() => {
    if (!rect) {
      return { display: "none" }
    }

    // eslint-disable-next-line no-undef
    const style: React.CSSProperties = { position: "fixed" }

    const xCenter = rect.left + rect.width / 2
    const yCenter = rect.top + rect.height / 2

    switch (direction) {
      case "top":
        style.left = xCenter
        style.top = rect.top
        style.transform = "translate(-50%, -120%)"
        break
      case "bottom":
        style.left = xCenter
        style.top = rect.bottom

        style.transform = "translate(-50%, 0)"
        break
      case "left":
        style.left = rect.left
        style.top = yCenter
        style.transform = "translate(-100%, -50%)"
        break
      case "right":
      default:
        style.left = rect.right
        style.top = yCenter
        style.transform = "translate(0, -50%)"
        break
    }

    return style
  }, [rect, direction])

  const getArrowClasses = useCallback(() => {
    switch (direction) {
      case "top":
        return "-bottom-1 left-1/2 -translate-x-1/2 rotate-45"
      case "bottom":
        return "-top-1 left-1/2 -translate-x-1/2 rotate-45"
      case "left":
        return "right-0 top-1/2 -translate-y-1/2 rotate-45"
      case "right":
      default:
        return "left-0 top-1/2 -translate-y-1/2 rotate-45"
    }
  }, [direction])

  const TooltipContent = useMemo(() => {
    return (
      <div
        role="tooltip"
        id={id}
        className={clsx(
          "z-40 max-w-[280px] text-center w-max whitespace-pre-line p-3 pointer-events-none animate-fade-in rounded-lg",
          variant === "primary"
            ? "bg-rodeo text-black"
            : "bg-gray-900 text-white",
          variant === "dark" ? "bg-gray-900 text-white" : ""
        )}
        style={getStyle()}
      >
        <div
          className={clsx(
            "absolute h-3 w-3",
            getArrowClasses(),
            variant === "primary" ? "bg-rodeo" : "bg-gray-900",
            variant === "dark" ? "bg-gray-900" : ""
          )}
        />
        {children}
      </div>
    )
  }, [children, id, variant, getStyle, getArrowClasses])

  return useDeferredValue(
    mounted && rect ? createPortal(TooltipContent, portalRef.current!) : null
  )
}

export type TooltipProps = {
  tooltipTitle?: string
  tooltip: ReactNode
  disabled?: boolean
  children: ReactElement
  variant?: "primary" | "secondary" | "dark"
  direction?: "top" | "right" | "bottom" | "left"
}

export const TooltipWrapper: FC<TooltipProps> = ({
  tooltipTitle,
  tooltip,
  disabled = false,
  children,
  variant = "primary",
  direction = "right",
}) => {
  const id = useId()
  const [isVisible, setIsVisible] = useState(false)
  const [rect, setRect] = useState<DOMRect | null>(null)
  const childRef = useRef<HTMLElement | null>(null)

  const onMouseOver = useCallback<MouseEventHandler>((_e) => {
    setIsVisible(true)
  }, [])
  const onMouseOut = useCallback<MouseEventHandler>(() => {
    setIsVisible(false)
  }, [])

  useEffect(() => {
    if (!isVisible) {
      return () => {}
    }
    const handleScroll = () => {
      setIsVisible(false)
    }
    if (childRef.current) {
      const bounding = childRef.current.getBoundingClientRect()
      setRect(bounding)
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isVisible])

  // Hide on Escape
  useEffect(() => {
    if (!isVisible) {
      return
    }
    const keyListener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsVisible(false)
      }
    }
    document.addEventListener("keydown", keyListener)
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener("keydown", keyListener)
    }
  }, [isVisible])

  if (disabled) {
    return children
  }

  const onlyChild = Children.only(children) as any
  const triggerProps = {
    onMouseOver,
    onMouseOut,
    ref: (node) => {
      if (typeof onlyChild.ref === "function") {
        onlyChild.ref(node)
      }
      childRef.current = node
    },
  }
  if (isVisible) {
    triggerProps["aria-describedby"] = id
  }

  const clonedChild = cloneElement(onlyChild, triggerProps)

  return (
    <>
      {clonedChild}
      {isVisible && (
        <Tooltip id={id} variant={variant} direction={direction} rect={rect}>
          {tooltipTitle ? (
            <>
              <XSmall className="font-medium" as="div">
                {tooltipTitle}
              </XSmall>
              <XSmall as="div">{tooltip}</XSmall>
            </>
          ) : (
            <XSmall as="div" className="font-medium">
              {tooltip}
            </XSmall>
          )}
        </Tooltip>
      )}
    </>
  )
}

export default TooltipWrapper
